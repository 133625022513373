<template>
    <section class="development">
        <div class="navigation d-flex mt-3">
            <span class="d-inline-block mx-2">
                <img src="../../assets/views/development/arrow-back.svg" alt="">
                <router-link
                    :to="{ name: 'Developments' }"
                    class="navigation__parent-link"
                >
                    Назад
                </router-link>
            </span>
        </div>

        <div class="development__content mt-3" v-if="product">
            <h2 class="development__title">{{ product.title }}</h2>

            <div class="d-flex flex-column flex-lg-row mt-4">
                <div class="col-12 col-lg-4 development__image-content">
                    <div class="d-flex justify-content-center">
                        <img
                            :src="getProductImage"
                            class="development__image"
                            alt=""
                        >
                    </div>

                    <div class="d-flex justify-content-center mt-3">
                        <img
                            class="d-inline-block"
                            :src="product.category.image"
                            alt=""
                        >
                    </div>
                </div>

                <div class="col-12 col-lg-7 pe-lg-1 pe-xl-3 pe-xxl-5 mt-5 mt-lg-0">
                    <div class="development__label">ОПИСАНИЕ</div>
                    <div class="development__text mt-4" v-html="product.description"/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { onMounted, computed } from "vue";
import { useRoute } from 'vue-router'
import { useStore } from "vuex";

export default {
    name: "Development",

    setup() {
        const route = useRoute()
        const store = useStore()

        /* Data */
        onMounted(() => {
            const { id } = route.params
            store.dispatch('products/getProductById', id)

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        /* Computed */
        const product = computed(() => store.getters['products/selectedProduct'])

        /* Methods */
        const getProductImage = computed(() => {
            return product.value.image
        })

        return {
            product,
            getProductImage,
        }
    }
}
</script>

